import { ACTIONS } from '@utils/constants';

export interface Actions {
    code: string;
    enabled?: boolean;
}

export interface AccessModule {
    code: string;
    actions?: ActionsList;
}

export interface ActionsList {
    [ACTIONS.PREVIEW]?: Actions;
    [ACTIONS.CREATE]?: Actions;
    [ACTIONS.UPDATE]?: Actions;
    [ACTIONS.DELETE]?: Actions;
    [ACTIONS.DOWNLOAD]?: Actions;
    [ACTIONS.UPLOAD]?: Actions;
    [ACTIONS.PAY]?: Actions;
    [ACTIONS.HISTORY]?: Actions;
    [ACTIONS.SHUTDOWN]?: Actions;
    [ACTIONS.CLONE]?: Actions;
    [ACTIONS.MANAGEMENT]?: Actions;
    [ACTIONS.SHOWTRANSACTION]?: Actions;
    [ACTIONS.TOGGLE]?: Actions;
}

export interface ModuleList {
    access_control: AccessModule;
    indicator: { code: string; actions?: IndicatorsActionsList };
    account_credentials: AccessModule;
    users: AccessModule;
    roles: AccessModule;
    holding: AccessModule;
    holdingDemo: AccessModule;
    holdingDemoUser: AccessModule;
    companies: AccessModule;
    agencies: AccessModule;
    hotels: AccessModule;
    automated_bookings: { code: string; actions?: BookingActionsList };
    transaction: { code: string; actions?: TransactionActionsList };
    transactionHyper: { code: string; actions?: TransactionHyperActionsList };
    payment_link: AccessModule;
    hyperpay_booking: { code: string; actions?: HyperpayActionsList };
    virtual_terminal: AccessModule;
    otas: AccessModule;
    trm: AccessModule;
    profile: AccessModule;
    parameterization: AccessModule;
    policy_criterias: AccessModule;
    policy_plans: AccessModule;
    booking_policies: AccessModule;
    apms: AccessModule;
    billing: AccessModule;
    balance: AccessModule;
}

export interface HyperpayActionsList extends ActionsList {
    listPayment?: Actions;
    setHyperBooking?: Actions;
    setHyperBolling?: Actions;
    listSplitById?: Actions;
    listSummary?: Actions;
    downloadSummary?: Actions;
    downloadHyperPay?: Actions;
    downloadHyperPaySplit?: Actions;
    downloadHyperPayVoucher?: Actions;
    downloadHyperPaySplitVoucher?: Actions;
}
export interface TransactionActionsList extends ActionsList {
    listPayment?: Actions;
    listSplit?: Actions;
    listSplitById?: Actions;
    downloadHyperPay?: Actions;
    downloadHyperPaySplit?: Actions;
    downloadHyperPayVoucher?: Actions;
    downloadHyperPaySplitVoucher?: Actions;
}
export interface TransactionHyperActionsList extends ActionsList {
    listPayment?: Actions;
    listSplit?: Actions;
    listSplitById?: Actions;
    downloadHyperPay?: Actions;
    downloadHyperPaySplit?: Actions;
    downloadHyperPayVoucher?: Actions;
    downloadHyperPaySplitVoucher?: Actions;
}
export interface BookingActionsList extends ActionsList {}
export interface IndicatorsActionsList extends ActionsList {
    listManagedPayments?: Actions;
    listCard?: Actions;
    optimizationTypes?: Actions;
    comparationValues?: Actions;
    comparationValuesPrice?: Actions;
    comparationApplied?: Actions;
    paymentTypes?: Actions;
    mainStatus?: Actions;
    distributionOtas?: Actions;
    otaDetail?: Actions;
}

export const PERMISSION_MODULE: ModuleList = {
    access_control: {
        code: 'access_control',
        actions: {
            [ACTIONS.UPDATE]: { code: 'post_access_control_change_password' },
        },
    },
    indicator: {
        code: 'indicators',
        actions: {
            ['listManagedPayments']: {
                code: 'list_indicators_managed_payments',
            },
            ['listCard']: {
                code: 'list_indicators_managed_payments_by_status',
            },
            ['optimizationTypes']: {
                code: 'list_indicators_optimization_by_types',
            },
            ['comparationValues']: {
                code: 'list_indicators_comparation_values',
            },
            ['comparationValuesPrice']: {
                code: 'list_indicators_comparation_values_prices',
            },
            ['comparationApplied']: {
                code: 'list_indicators_comparation_applied_declined',
            },
            ['paymentTypes']: {
                code: 'list_indicators_payment_types',
            },
            ['mainStatus']: {
                code: 'list_indicators_main_status',
            },
            ['distributionOtas']: {
                code: 'list_indicators_distribution_otas',
            },
            ['otaDetail']: {
                code: 'list_indicators_ota_detail',
            },
        },
    },
    account_credentials: {
        code: 'account_credentials',
        actions: {
            [ACTIONS.CREATE]: { code: 'create_account_credentials' },
            [ACTIONS.PREVIEW]: { code: 'list_account_credentials' },
            [ACTIONS.DELETE]: { code: 'delete_account_credentials' },
        },
    },
    users: {
        code: 'users',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_users' },
            [ACTIONS.CREATE]: { code: 'create_users' },
            [ACTIONS.UPDATE]: { code: 'update_users' },
            [ACTIONS.UPLOAD]: { code: 'upload_document' },
            [ACTIONS.DOWNLOAD]: { code: 'download_document' },
            [ACTIONS.TOGGLE]: { code: 'delete_users' },
            [ACTIONS.DELETE]: { code: 'delete_users' },
            [ACTIONS.HISTORY]: {
                code: 'view_account_credentials_management_users',
            },
            [ACTIONS.MANAGEMENT]: { code: 'view_permissions_management_users' },
        },
    },
    roles: {
        code: 'roles',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_roles' },
            [ACTIONS.CREATE]: { code: 'create_roles' },
            [ACTIONS.UPDATE]: { code: 'update_roles' },
            [ACTIONS.TOGGLE]: { code: 'delete_roles' },
            [ACTIONS.MANAGEMENT]: { code: 'associate_roles' },
            [ACTIONS.DELETE]: { code: 'delete_roles' },
        },
    },
    holding: {
        code: 'holding',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_holdings' },
            [ACTIONS.CREATE]: { code: 'create_holdings' },
            [ACTIONS.UPDATE]: { code: 'update_holdings' },
            [ACTIONS.TOGGLE]: { code: 'delete_holdings' },
            [ACTIONS.DELETE]: { code: 'delete_holdings' },
        },
    },
    holdingDemo: {
        code: 'holdingDemo',
    },
    holdingDemoUser: {
        code: 'list_demo',
    },
    companies: {
        code: 'companies',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_companies' },
            [ACTIONS.CREATE]: { code: 'create_companies' },
            [ACTIONS.UPDATE]: { code: 'update_companies' },
            [ACTIONS.TOGGLE]: { code: 'delete_companies' },
            [ACTIONS.HISTORY]: { code: 'list_companies' },
            [ACTIONS.DELETE]: { code: 'delete_companies' },
        },
    },
    agencies: {
        code: 'agencies',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_agencies' },
            [ACTIONS.CREATE]: { code: 'create_agencies' },
            [ACTIONS.UPDATE]: { code: 'update_agencies' },
            [ACTIONS.TOGGLE]: { code: 'delete_agencies' },
            [ACTIONS.MANAGEMENT]: { code: 'management_agencies' },
            [ACTIONS.DELETE]: { code: 'delete_agencies' },
        },
    },
    hotels: {
        code: 'hotels',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_hotels' },
            [ACTIONS.CREATE]: { code: 'create_hotels' },
            [ACTIONS.UPDATE]: { code: 'update_hotels' },
            [ACTIONS.TOGGLE]: { code: 'delete_hotels' },
            [ACTIONS.MANAGEMENT]: { code: 'view_policies_manager_hotels' },
            [ACTIONS.DELETE]: { code: 'delete_hotels' },
            [ACTIONS.CLONE]: { code: 'delete_automatizations' },
        },
    },
    automated_bookings: {
        code: 'automated_bookings',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_automated_bookings' },
            [ACTIONS.CREATE]: { code: 'create_automated_bookings' },
            [ACTIONS.UPDATE]: { code: 'update_automated_booking' },
            [ACTIONS.TOGGLE]: { code: 'update_automated_bookings_status' },
            [ACTIONS.PAY]: { code: 'update_automated_bookings_status' },
            [ACTIONS.DOWNLOAD]: {
                code: 'download_automated_bookings_report_xls',
            },
            [ACTIONS.CLONE]: { code: 'download_automated_bookings_voucher' },
            [ACTIONS.SHOWTRANSACTION]: { code: 'list_transactions' },
        },
        /* 
        verify_automated_pending_payments
        list_automated_payment_status
        set_automated_bookings
        post_automated_bookings_payments
        post_automated_bookings_early_checkouts

        post_automated_booking_prepayment
        post_automated_booking_retry
        list_automated_bookings_headers_indicators 
        ================= INTERNOS =================
        set_automated_bookings_payments
        set_automated_bookings_early_checkouts*/
    },
    transaction: {
        code: 'transaction',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_transactions' },
            [ACTIONS.DOWNLOAD]: { code: 'download_transactions_xls' },
            [ACTIONS.UPDATE]: { code: 'download_automated_bookings_voucher' },
            [ACTIONS.PAY]: { code: 'post_hyperpay_booking_payment' },
            ['listPayment']: { code: 'list_hyperpay_payments' },
            ['listSplit']: { code: 'list_hyperpay_payments_splits_bookings' },
            ['listSplitById']: { code: 'list_hyperpay_payments_splits' },
            ['downloadHyperPay']: { code: 'download_hyperpay_payments_xls' },
            ['downloadHyperPaySplit']: {
                code: 'download_hyperpay_payments_splits_xls',
            },
            ['downloadHyperPayVoucher']: {
                code: 'download_hyperpay_payments_voucher',
            },
            ['downloadHyperPaySplitVoucher']: {
                code: 'download_hyperpay_payments_splits_voucher',
            },
        },
    },
    transactionHyper: {
        code: 'list_hyperpay_payments_splits_bookings',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_transactions' },
            [ACTIONS.DOWNLOAD]: { code: 'download_transactions_xls' },
            [ACTIONS.UPDATE]: { code: 'download_automated_bookings_voucher' },
            [ACTIONS.PAY]: { code: 'post_hyperpay_booking_payment' },
            ['listPayment']: { code: 'list_hyperpay_payments' },
            ['listSplit']: { code: 'list_hyperpay_payments_splits_bookings' },
            ['listSplitById']: { code: 'list_hyperpay_payments_splits' },
            ['downloadHyperPay']: { code: 'download_hyperpay_payments_xls' },
            ['downloadHyperPaySplit']: {
                code: 'download_hyperpay_payments_splits_xls',
            },
            ['downloadHyperPayVoucher']: {
                code: 'download_hyperpay_payments_voucher',
            },
            ['downloadHyperPaySplitVoucher']: {
                code: 'download_hyperpay_payments_splits_voucher',
            },
        },
    },
    payment_link: {
        code: 'payment_link',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_payment_link' },
            [ACTIONS.CREATE]: { code: 'create_payment_link' },
            [ACTIONS.UPDATE]: { code: 'post_payment_link_refound' },
            [ACTIONS.SHOWTRANSACTION]: { code: 'list_transactions' },
            [ACTIONS.DOWNLOAD]: { code: 'download_payment_link_xls' },
            [ACTIONS.DELETE]: { code: 'delete_payment_link' },
        },
        /* 
        ==REVISAR Y ASIGNAR CODIGO==
        verify_payment_link_pending_payments
        verify_payment_link_expired_payments
        verify_virtual_terminal_transaction_status
        verify_payment_link_pending_refounds
        */
    },
    hyperpay_booking: {
        code: 'hyperpay_booking',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_hyperpay_booking' },
            [ACTIONS.CREATE]: { code: 'create_hyperpay_booking' },
            [ACTIONS.PAY]: { code: 'post_hyperpay_booking_payment' },
            [ACTIONS.DOWNLOAD]: { code: 'download_hyperpay_booking_xls' },
            ['listPayment']: { code: 'list_hyperpay_payments' },
            ['setHyperBooking']: { code: 'set_hyperpay_bookings_cathcment' },
            ['setHyperBolling']: { code: 'set_hyperpay_billing_cut' },
            ['listSplitById']: { code: 'list_hyperpay_payments_splits' },
            ['listSummary']: { code: 'list_hyperpay_booking_payments_summary' },
            ['downloadSummary']: {
                code: 'download_hyperpay_booking_summary_xls',
            },
            ['downloadHyperPay']: { code: 'download_hyperpay_payments_xls' },
            ['downloadHyperPaySplit']: {
                code: 'download_hyperpay_payments_splits_xls',
            },
            ['downloadHyperPayVoucher']: {
                code: 'download_hyperpay_payments_voucher',
            },
            ['downloadHyperPaySplitVoucher']: {
                code: 'download_hyperpay_payments_splits_voucher',
            },
        },
    },
    virtual_terminal: {
        code: 'virtual_terminal',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_virtual_terminal' },
            [ACTIONS.CREATE]: { code: 'post_virtual_terminal_payment' },
            [ACTIONS.SHUTDOWN]: { code: 'post_virtual_terminal_refound' },
            [ACTIONS.CLONE]: { code: 'download_virtual_terminal_voucher' },
            [ACTIONS.DOWNLOAD]: { code: 'download_virtual_terminal_xls' },
        },
        /* 
        PENDING TO INTEGRATE THIS CODES:
        verify_virtual_terminal_pending_refound
        verify_virtual_terminal_transaction_status
        list_virtual_terminal_payvalida_pse */
    },
    otas: {
        code: 'otas',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_otas' },
            [ACTIONS.CREATE]: { code: 'create_otas' },
            [ACTIONS.UPDATE]: { code: 'update_otas' },
            [ACTIONS.TOGGLE]: { code: 'toggle_status_otas' },
            [ACTIONS.DELETE]: { code: 'toggle_archived_otas' },
        },
    },
    trm: {
        code: 'trm',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_trm' },
            [ACTIONS.CREATE]: { code: 'create_trm' },
            [ACTIONS.UPDATE]: { code: 'update_trm' },
            [ACTIONS.TOGGLE]: { code: 'toggle_status_trm' },
            [ACTIONS.DELETE]: { code: 'toggle_archived_trm' },
        },
    },
    profile: {
        code: 'access_control',
        actions: {},
    },
    parameterization: {
        code: 'parameterization',
        actions: {},
    },
    policy_criterias: {
        code: 'policy_criterias',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_policy_criterias' },
            [ACTIONS.CREATE]: { code: 'create_policy_criterias' },
            [ACTIONS.UPDATE]: { code: 'update_policy_criterias' },
            [ACTIONS.TOGGLE]: { code: 'toggle_status_policy_criterias' },
            [ACTIONS.DELETE]: { code: 'toggle_archived_policy_criterias' },
        },
    },
    policy_plans: {
        code: 'policy_plans',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_policy_plans' },
            [ACTIONS.CREATE]: { code: 'create_policy_plans' },
            [ACTIONS.UPDATE]: { code: 'update_policy_plans' },
            [ACTIONS.TOGGLE]: { code: 'toggle_status_policy_plans' },
            [ACTIONS.DELETE]: { code: 'toggle_archived_policy_plans' },
        },
    },
    booking_policies: {
        code: 'booking_policies',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_booking_policies' },
            [ACTIONS.CREATE]: { code: 'create_booking_policies' },
            [ACTIONS.UPDATE]: { code: 'update_booking_policies' },
            [ACTIONS.MANAGEMENT]: { code: 'create_booking_policies' },
            [ACTIONS.TOGGLE]: { code: 'toggle_status_booking_policies' },
            [ACTIONS.DELETE]: { code: 'toggle_archived_booking_policies' },
        },
    },
    apms: {
        code: 'apms',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_apms' },
            [ACTIONS.CREATE]: { code: 'post_apms' },
            [ACTIONS.CLONE]: { code: 'download_apms_voucher' },
            [ACTIONS.DOWNLOAD]: { code: 'download_apms_xls' },
        },
    },
    billing: {
        code: 'billing',
        actions: {
            [ACTIONS.PREVIEW]: { code: 'list_billing' },
            [ACTIONS.UPLOAD]: { code: 'upload_billing' },
            [ACTIONS.DOWNLOAD]: { code: 'download_billing' },
        },
    },
    balance: {
        code: 'payouts',
        actions: {
            /* list_company_transactions_payouts */
            [ACTIONS.PREVIEW]: {
                code: 'list_company_transactions_detail_payouts',
            },
            [ACTIONS.HISTORY]: { code: 'list_company_balance_payouts' },
            [ACTIONS.PAY]: { code: 'create_summary_cut_payouts' },
        },
    },
};
