import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Subscription, of, switchMap, tap } from 'rxjs';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';

import { UserInfo } from '@core/models/user';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from '@environments/environment';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import { PermissionModule } from '@core/models/permissionModule';
import { PermissionList } from '@core/models/role';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly userOnSession = 'v2/users/me';
    private readonly loginEndpoint = 'v2/auth/login';
    private readonly postValidateOtp = 'v2/auth/validate-otp';
    private readonly createAccountEndpoint = 'api/v1/auth/register';
    private readonly forgotPasswordEndpoint = 'v2/auth/forgot-password';
    private readonly changePasswordEndpoint = 'v2/auth/recovery-password';

    /**
     * Store for user on session
     */
    private $session: BehaviorSubject<UserInfo> = new BehaviorSubject(null);
    private $permissionModule: BehaviorSubject<PermissionList[]> =
        new BehaviorSubject(null);
    private $actionsByModule: BehaviorSubject<PermissionList[]> =
        new BehaviorSubject(null);

    public readonly session: Observable<UserInfo> =
        this.$session.asObservable();

    public readonly permissionModule: Observable<PermissionList[]> =
        this.$permissionModule.asObservable();
    public readonly ActionsByModule: Observable<PermissionList[]> =
        this.$actionsByModule.asObservable();

    public subscription: Subscription;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessTokenKey', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessTokenKey') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set authenticated(value: string) {
        localStorage.setItem('authenticated', value);
    }

    get authenticated(): string {
        return localStorage.getItem('authenticated') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     * @param email
     */
    forgotPassword(form: Object): Observable<{ msg: string }> {
        return this._httpClient.post<{ msg: string }>(
            `${environment.host}${this.forgotPasswordEndpoint}`,
            form
        );
    }

    /**
     * Forgot password
     * @param email
     */
    valdiateOTP(form: Object): Observable<{ msg: string }> {
        return this._httpClient.post<{ msg: string }>(
            `${environment.host}${this.postValidateOtp}`,
            form
        );
    }

    /**
     * Reset password
     *
     * @param form
     */
    resetPassword(form: Object): Observable<{ msg: string }> {
        return this._httpClient.post<{ msg: string }>(
            `${environment.host}${this.changePasswordEndpoint}`,
            form
        );
    }

    /**
     * @description sign in on app
     */
    signIn(form: Object): Observable<{ token: string }> {
        return this._httpClient
            .post(`${environment.host}${this.loginEndpoint}`, form)
            .pipe(
                switchMap((response: { token: string }) => {
                    // Store the access token in the local storage
                    this.accessToken = response?.token;

                    // Set the authenticated flag to true
                    this.authenticated = 'true';

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * @description validate email
     * @param form
     */
    validateEmail(form: {
        email: string;
        otp: string;
    }): Observable<{ msg: string }> {
        return this._httpClient.put<{ msg: string }>(
            `${environment.host}api/v1/auth/email-verification`,
            form
        );
    }

    /**
     * @description Resend email validation
     * @param form
     */
    resendEmailValidation(form: {
        email: string;
    }): Observable<{ msg: string }> {
        return this._httpClient.post<{ msg: string }>(
            `${environment.host}api/v1/auth/email-verification/resent`,
            form
        );
    }

    /**
     * Do the logic of login
     * @param credentials
     */
    public getUserMe(): Observable<UserInfo> {
        const path = `${environment.host}${this.userOnSession}`;
        return this._httpClient
            .get<UserInfo>(path)
            .pipe(tap((user) => this.$session.next(user)));
    }

    public checkAuthentication(): Promise<UserInfo | null> {
        return new Promise((resolve) => {
            let session = this.$session.getValue();
            if (session) {
                resolve(session);
            } else if (this.accessToken) {
                this.getUserMe().subscribe({
                    next: (rta) => {
                        if (rta) {
                            let permissionList: PermissionList[] = [];

                            rta.roles.forEach((item) => {
                                permissionList = [
                                    ...permissionList,
                                    ...item.permissions_list,
                                ];
                            });

                            // Validate if exist individual permission
                            if (
                                rta.permissions_list &&
                                rta.permissions_list.length
                            ) {
                                permissionList = [
                                    ...permissionList,
                                    ...rta.permissions_list,
                                ];
                            }

                            this.$permissionModule.next(permissionList);
                        }
                        // resolve
                        resolve(rta);
                    },
                    error: () => {
                        (err) => {
                            this.signOut();
                            resolve(null);
                        };
                    },
                });
            } else {
                this.signOut();
                resolve(null);
            }
        });
    }

    public checkAccessModule(
        code: string,
        secondaryCodes?: string[]
    ): Promise<boolean> {
        return new Promise(async (resolve) => {
            let permission = this.$permissionModule.getValue();
            let userInfo = this.$session.getValue();

            // Validate hyperadmin user
            if (userInfo?.is_hyperadmin) {
                resolve(true);
                return;
            }

            if (permission && permission.length) {
                resolve(this.findModules(permission, code, secondaryCodes));
            } else {
                resolve(false);
            }
        });
    }

    /**
     *
     * @param modules (required)
     * @param code (required)
     * @returns
     */
    private findModules(
        modules: PermissionList[],
        code: string,
        secondaryCodes?: string[]
    ): boolean {
        let hasPermissions: boolean;
        let actions: PermissionList[];

        // find permissions to current module
        hasPermissions = !!modules.find((item) => item.module_code == code);

        // validate permission to actions
        actions = modules.filter((item) => item.module_code == code);

        if (secondaryCodes && secondaryCodes.length) {
            secondaryCodes.forEach((value) => {
                actions = [
                    ...actions,
                    ...modules.filter((item) => item.module_code == value),
                ];
            });
        }

        // save permission to current module
        this.$actionsByModule.next(actions);

        if (!hasPermissions) {
            this._router.navigateByUrl('/main');
        }

        return hasPermissions;
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.token) {
                        this.accessToken = response.token;
                    }

                    // Set the authenticated flag to true
                    this.authenticated = 'true';

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessTokenKey');

        // Set the authenticated flag to false
        localStorage.removeItem('authenticated');

        this.$session.next(null);

        // Redirect to login
        this._router.navigate(['/account/sign-in']);
        // Return the observable
        return of(true);
    }

    /**Register a user
     * @param form
     */
    signUp(form: Object): Observable<{ msg: string }> {
        return this._httpClient.post<{ msg: string }>(
            `${environment.host}${this.createAccountEndpoint}`,
            form
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
