import { FuseNavigationItem } from '@fuse/components/navigation';

import { MAT_SVG_ICON } from '@utils/constants';
import { PERMISSION_MODULE } from '@core/permissions/permission.type';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'collections',
        type: 'group',
        title: 'COBROS OPTIMIZADOS',
        hidden: () => true,
        children: [
            {
                id: PERMISSION_MODULE.indicator.code,
                title: 'Estadisticas',
                type: 'basic',
                icon: MAT_SVG_ICON.TREND,
                link: '/main/dashboard',
                hidden: () => false,
            },
            {
                id: PERMISSION_MODULE.virtual_terminal.code,
                title: 'Terminal virtual',
                type: 'basic',
                icon: MAT_SVG_ICON.VIRTUAL_TERMINAL,
                link: '/main/dataphone',
                hidden: () => false,
            },
            {
                id: PERMISSION_MODULE.payment_link.code,
                title: 'QR & Links',
                type: 'basic',
                icon: MAT_SVG_ICON.LINK,
                link: '/main/link',
                hidden: () => false,
            },
            {
                id: PERMISSION_MODULE.automated_bookings.code,
                title: 'Automatizados',
                type: 'basic',
                icon: 'heroicons_outline:arrow-path',
                link: '/main/bookings',
                hidden: () => false,
            },
            {
                id: PERMISSION_MODULE.transaction.code,
                title: 'Transacciones',
                type: 'basic',
                icon: MAT_SVG_ICON.TREND,
                link: '/main/transactions/hotel/list',
                hidden: () => false,
            },
            {
                id: PERMISSION_MODULE.balance.code,
                title: 'Balance',
                type: 'basic',
                icon: MAT_SVG_ICON.SCALE,
                link: '/main/balance/overview',
                hidden: () => false,
            },
        ],
    },
    {
        id: 'collpase-hotel-management',
        type: 'group',
        title: 'ADMINISTRACIÓN',
        hidden: () => true,
        children: [
            {
                //id: PERMISSION_MODULE.virtual_terminal.code,
                title: 'Pagos alternativos',
                type: 'basic',
                icon: MAT_SVG_ICON.ALTERNATIVE,
                link: '/main/payment-alternative/list',
                hidden: () => false,
            },

            {
                id: PERMISSION_MODULE.billing.code,
                title: 'Facturación',
                type: 'basic',
                icon: MAT_SVG_ICON.BILLING,
                link: '/main/billing/list',
                hidden: () => false,
            },
            {
                id: 'organizations',
                title: 'Organizaciones',
                type: 'collapsable',
                icon: MAT_SVG_ICON.PORTAL_OUTLINE,
                hidden: () => true,
                children: [
                    {
                        id: PERMISSION_MODULE.holding.code,
                        title: 'Grupo empresarial',
                        type: 'basic',
                        icon: 'heroicons_outline:building-office-2',
                        link: '/main/holdings-management',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.holdingDemo.code,
                        title: 'Grupo empresarial Demo',
                        type: 'basic',
                        icon: 'apartment',
                        link: '/main/holdingDemo',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.companies.code,
                        title: 'Razón social',
                        type: 'basic',
                        icon: 'heroicons_outline:building-library',
                        link: '/main/company-management',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.agencies.code,
                        title: 'Agencias',
                        type: 'basic',
                        icon: MAT_SVG_ICON.USER_GROUP,
                        link: '/main/agency-management',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.hotels.code,
                        title: 'Propiedades',
                        type: 'basic',
                        icon: MAT_SVG_ICON.OFFICE_OUTLINE,
                        link: '/main/hotel-management',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.holdingDemoUser.code,
                        title: 'Solicitud Demo',
                        type: 'basic',
                        icon: MAT_SVG_ICON.CLIPBOARD,
                        link: '/main/demo/register',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.holdingDemoUser.code,
                        title: 'Estado de demo',
                        type: 'basic',
                        icon: MAT_SVG_ICON.CHECK_CIRCLE,
                        link: '/main/demo/status',
                        hidden: () => false,
                    },
                ],
            },
            {
                id: 'policies_management',
                title: 'Gestión política',
                type: 'collapsable',
                icon: 'heroicons_outline:scale',
                hidden: () => true,
                children: [
                    {
                        id: PERMISSION_MODULE.policy_criterias.code,
                        title: 'Criterios',
                        type: 'basic',
                        icon: 'heroicons_outline:cog',
                        link: '/main/criterias-management',
                        hidden: () => true,
                    },
                    {
                        id: PERMISSION_MODULE.policy_plans.code,
                        title: 'Planes',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-document-check',
                        link: '/main/plans-management',
                        hidden: () => true,
                    },
                    {
                        id: PERMISSION_MODULE.otas.code,
                        title: 'Otas',
                        type: 'basic',
                        icon: 'heroicons_outline:globe-alt',
                        link: '/main/otas-management',
                        hidden: () => true,
                    },
                ],
            },

            {
                id: 'hyperpay_charges',
                title: 'Hyperpay',
                type: 'collapsable',
                icon: 'heroicons_outline:globe-alt',
                hidden: () => true,
                children: [
                    {
                        id: PERMISSION_MODULE.hyperpay_booking.code,
                        title: 'Reservas',
                        type: 'basic',
                        icon: MAT_SVG_ICON.RESERVATION,
                        link: '/main/bookings-room',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.hyperpay_booking.code,
                        title: 'Por pagar',
                        type: 'basic',
                        icon: MAT_SVG_ICON.CURRENCY,
                        link: '/main/payments/summary',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.transactionHyper.code,
                        title: 'Transacciones',
                        type: 'basic',
                        icon: MAT_SVG_ICON.CLIPBOARD,
                        link: '/main/transactionsHyper/hyper/list',
                        hidden: () => false,
                    },
                ],
            },
            {
                id: PERMISSION_MODULE.parameterization.code,
                title: 'Parámetros',
                type: 'collapsable',
                icon: 'heroicons_outline:finger-print',
                hidden: () => true,
                children: [
                    {
                        id: PERMISSION_MODULE.parameterization.code,
                        title: 'Parametrización',
                        type: 'basic',
                        icon: 'heroicons_outline:arrow-path',
                        link: '/main/parameterization-management/list',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.parameterization.code,
                        title: 'Documentos',
                        type: 'basic',
                        icon: 'heroicons_outline:document-duplicate',
                        link: '/main/parameterization-management/documents/list',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.trm.code,
                        title: 'TRM',
                        type: 'basic',
                        icon: 'heroicons_outline:arrow-path',
                        link: '/main/trm-management',
                        hidden: () => true,
                    },
                ],
            },
            {
                id: 'configuration',
                title: 'Configuración',
                type: 'collapsable',
                icon: 'heroicons_outline:cog-8-tooth',
                hidden: () => true,
                children: [
                    {
                        id: PERMISSION_MODULE.users.code,
                        title: 'Usuarios',
                        type: 'basic',
                        icon: MAT_SVG_ICON.USER_OUTLINE,
                        link: '/main/user-management',
                        hidden: () => false,
                    },
                    {
                        id: PERMISSION_MODULE.roles.code,
                        title: 'Roles',
                        type: 'basic',
                        icon: MAT_SVG_ICON.ROLES_OUTLINE,
                        link: '/main/roles-management',
                        hidden: () => false,
                    },
                ],
            },
        ],
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
