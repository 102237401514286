import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { MAT_SVG_ICON, TYPE_MESSAGE } from '@utils/constants';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
})
export class MessageComponent {
    public type = {
        [TYPE_MESSAGE.SUCCESS]: {
            icon: MAT_SVG_ICON.CHECK_CIRCLE,
            class: 'w-32 h-32 text-[#089c84]',
            background: 'min-w-32 bg-[#089c84]',
        },
        [TYPE_MESSAGE.WARN]: {
            icon: MAT_SVG_ICON.WARNING,
            class: 'w-32 h-32 text-orange-600',
            background: 'min-w-32 bg-orange-600',
        },
        [TYPE_MESSAGE.ERROR]: {
            icon: MAT_SVG_ICON.ERROR_OUTLINE,
            class: 'w-32 h-32 text-red-400',
            background: 'min-w-32 bg-red-400',
        },
    };

    public ICONS = MAT_SVG_ICON;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            message: string;
            submitText: string;
            typeMessage: TYPE_MESSAGE;
            showBtnCancel: boolean;
            cancelText: string;
        },
        public dialogRef: MatDialogRef<MessageComponent>
    ) {}
}
