import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { TYPE_MESSAGE } from '@utils/constants';
import { MessageComponent } from '@shared/components/message/message.component';

export const DEFAULT_MODAL_CONFIG: MatDialogConfig = {
    width: '400px',
    autoFocus: false,
};

@Injectable({
    providedIn: 'root',
})
export class MsgBoxService {
    /**
     * Constructor
     */
    constructor(private dialog: MatDialog) {}
    /**
     * Create a alert modal.
     * @param msg
     * @param title
     */
    public Open(
        modalComponent: any,
        width: string,
        data?,
        panelClass?
    ): Promise<any> {
        return this.dialog
            .open(modalComponent, {
                ...DEFAULT_MODAL_CONFIG,
                width: width,
                data: data,
                panelClass: panelClass ? panelClass : 'custom-modalbox',
            })
            .afterClosed()
            .toPromise();
    }

    /**
     * Create a alert modal.
     * @param msg
     * @param title
     */
    public alert(
        title: string = '',
        message: string,
        submitText = 'Aceptar',
        typeMessage?: TYPE_MESSAGE,
        cancelText='Cancelar'
    ): Promise<any> {
        return this.dialog
            .open(MessageComponent, {
                ...DEFAULT_MODAL_CONFIG,
                panelClass: 'custom-modalbox',
                data: {
                    title,
                    message,
                    submitText,
                    typeMessage,
                    showBtnCancel: false,
                    cancelText
                },
            })
            .afterClosed()
            .toPromise();
    }

    /**
     * Create a confirm modal.
     * @param msg
     * @param title
     */
    public confirm(
        title: string = '',
        message: string,
        submitText = 'Aceptar',
        cancelText='Cancelar'
    ): Promise<any> {
        return this.dialog
            .open(MessageComponent, {
                ...DEFAULT_MODAL_CONFIG,
                panelClass: 'custom-modalbox',
                data: {
                    title,
                    message,
                    submitText,
                    showBtnCancel: true,
                    cancelText
                },
            })
            .afterClosed()
            .toPromise();
    }

    /* public async snackBar(
        message: string,
        action: string
    ): Promise<MatSnackBarDismiss> {
        return new Promise(() =>
            lastValueFrom(this._snackBar.open(message, action).afterDismissed())
        );
    } */
}
